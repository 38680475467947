/** @format */

:root {
	--toastify-color-light: rgba(83, 69, 116, 0.8);
	--toastify-color-dark: #121212;
	--toastify-color-info: #3498db;
	--toastify-color-success: #58d0ff;
	--toastify-color-warning: #f1c40f;
	--toastify-color-error: #c71a4e;
	--toastify-color-transparent: rgba(255, 255, 255, 0.7);

	--toastify-icon-color-info: var(--toastify-color-info);
	--toastify-icon-color-success: var(--toastify-color-success);
	--toastify-icon-color-warning: var(--toastify-color-warning);
	--toastify-icon-color-error: var(--toastify-color-error);

	--toastify-toast-width: 320px;
	--toastify-toast-background: rgb(111, 92, 146);
	--toastify-toast-min-height: 64px;
	--toastify-toast-max-height: 800px;
	--toastify-font-family: sans-serif;
	--toastify-z-index: 9999;

	--toastify-text-color-light: #ffffff;
	--toastify-text-color-dark: #fff;

	--toastify-text-color-info: #fff;
	--toastify-text-color-success: #fff;
	--toastify-text-color-warning: #fff;
	--toastify-text-color-error: #fff;

	--toastify-spinner-color: #616161;
	--toastify-spinner-color-empty-area: #e0e0e0;

	--toastify-color-progress-light: linear-gradient(
		to right,
		#4cd964,
		#5ac8fa,
		#007aff,
		#34aadc,
		#5856d6,
		#ff2d55
	);
	--toastify-color-progress-dark: #bb86fc;
	--toastify-color-progress-info: var(--toastify-color-info);
	--toastify-color-progress-success: var(--toastify-color-success);
	--toastify-color-progress-warning: var(--toastify-color-warning);
	--toastify-color-progress-error: var(--toastify-color-error);
}
